'use client';

import { isInitialized } from '@fullstory/browser';
import { useState } from 'react';
import { useInterval } from 'react-use';

export function useFullStoryInitialized(): boolean {
	const [initialized, setInitialized] = useState(false);
	const [delay, setDelay] = useState(1);

	useInterval(() => {
		const newInitialized = isInitialized();
		if (!newInitialized) {
			setDelay(Math.max(delay * 2, 100));
			return;
		}

		setInitialized(true);
		setDelay(null);
	}, delay);

	return initialized;
}
