'use client';

import { isTruthy } from '@dte/otw/utils/booleans/src/isTruthy';
import { FullStory } from '@fullstory/browser';
import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { isProd } from '../../common/util/environmentConfig';
import { useFullStoryInitialized } from './useFullStoryInitialized';

const fullStoryEnabled = !isTruthy(process.env.NEXT_PUBLIC_FULLSTORY_DEVMODE);

export interface ReadyResponse {
	sessionUrl: string;
	settings: Readonly<object>;
	debug?: string;
}
export type ReadyCallback = (data: ReadyResponse) => void;

export function useFullStoryReady(): [boolean, () => void] {
	const initialized = useFullStoryInitialized();

	const [ready, setReady] = useState(false);
	const [delay, setDelay] = useState(null);

	useEffect(() => {
		// If we have devMode enabled to suppress telemetry, FullStory will never be "ready"
		if (!fullStoryEnabled) {
			return;
		}

		// Start checking for ready after we've initialized
		if (initialized && !ready) {
			setDelay(1);
		}
	}, [initialized, ready]);

	// This could be called by either manual checks to see if we can get a session URL or a ReadyCallback
	const updateReady = useCallback((data?: ReadyResponse) => {
		console.log('Full story ready, session url: ', data?.sessionUrl);
		if (!isProd) {
			console.log('Full story ready, session data: ', data);
		}
		setReady(true);
		setDelay(null);
	}, []);

	useInterval(() => {
		// Don't run until initialized
		if (!initialized) {
			return;
		}

		const sessionUrl = FullStory('getSession');
		console.log(`session from FullStory: [ ${sessionUrl} ], delay: [ ${delay} ]`);

		if (!sessionUrl) {
			setDelay(Math.max(delay * 2, 100));
			return;
		}

		updateReady({ sessionUrl, settings: {}, debug: 'Setting ready from manual check' });
	}, delay);

	return [ready, updateReady];
}
