'use client';
import { FullStory } from '@fullstory/browser';
import { useEffect } from 'react';
import { isProd } from '../../common/util/environmentConfig';
import { useFullStoryReady } from './useFullStoryReady';

export function useLogFullStorySession(): void {
	const [ready] = useFullStoryReady();

	useEffect(() => {
		if (!ready) {
			return;
		}

		const sessionId = FullStory('getSession', { format: 'id' });
		console.log(`FullStory session: ${sessionId}`);

		if (!isProd) {
			if (sessionId) {
				const sessionUrl = FullStory('getSession', { format: 'url' });
				console.log(`FullStory session URL: ${sessionUrl}`);
			}
		}
	}, [ready]);
}
