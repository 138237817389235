'use client';

import { isTruthy } from '@dte/otw/utils/booleans/src/isTruthy';
import { type SnippetOptions, init, isInitialized } from '@fullstory/browser';
import { type PropsWithChildren, type ReactNode, useEffect, useRef } from 'react';
import { isProd } from '../../common/util/environmentConfig';
import { useFullStoryReady } from './useFullStoryReady';
import { useLogFullStorySession } from './useLogFullStorySession';

const snippetOptions: SnippetOptions = {
	orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG,
	devMode: isTruthy(process.env.NEXT_PUBLIC_FULLSTORY_DEVMODE),
	debug: isTruthy(process.env.NEXT_PUBLIC_FULLSTORY_DEBUG),
};

function useInitFullStory(): void {
	const calledInit = useRef(false);
	const [, updateReady] = useFullStoryReady();

	useEffect(() => {
		// FullStory is already initialized
		if (isInitialized()) {
			return;
		}

		// Prevent executing twice in React Strict mode
		if (calledInit.current) {
			return;
		}
		calledInit.current = true;

		if (!isProd) {
			console.log('FullStory init', snippetOptions);
		}
		init(snippetOptions, updateReady);
	}, [updateReady]);
}

export function FullStoryInit(props: PropsWithChildren): ReactNode {
	useInitFullStory();
	useLogFullStorySession();

	return props.children;
}
